import { capitalize } from 'lodash'

/*
    each program will have services and send in only offers relevant to that program
*/
export const applyOfferToServices = (services = [], offers = []) => {
  if (!offers.length || !services.length) return services
  offers.forEach((i) => {
    if (i.appliedTo === 'first-app') {
      services[0].netPrice = calcuateDiscountTypeAndAmount(
        i,
        services[0].grossPrice
      )
    } else {
      /// total program
      services = services.map((s) => {
        const modifedServicePrice = calcuateDiscountTypeAndAmount(
          i,
          s.grossPrice
        )
        s.netPrice = modifedServicePrice
        return s
      })
    }
  })
  return services
}

export const calcuateDiscountTypeAndAmount = (offer, price) => {
  const amount = parseFloat(offer.discountAmount)
  let newPrice = price
  if (offer.discountType === 'percentage-off') {
    newPrice = ((100 - amount) / 100) * price
  } else if (offer.discountType === 'fixed-price') {
    newPrice = amount
  } else if (offer.discountType === 'discounted-price-table') {
    // TODO: need to have a better solution
    newPrice = amount
  }

  return Math.round((newPrice + Number.EPSILON) * 100) / 100
}

export const createOfferText = (
  price,
  offer,
  alternativeLabel = 'Application',
  currency = '$',
  prePayDiscount = null
) => {
  alternativeLabel = alternativeLabel
    .split(' ')
    .map((t) => capitalize(t))
    .join(' ')
  let text = ''
  if (offer.hide) return text
  const amount = parseFloat(offer.discountAmount)
  if (offer.appliedTo === 'first-app') {
    if (offer.discountType === 'fixed-price') {
      const discount = calcuateDiscountTypeAndAmount(offer, price)
      text = `${currency}${discount} for the 1st ${alternativeLabel}. ${currency}${parseFloat(
        `${price}`
      ).toFixed(2)} for the remaining ${alternativeLabel}(s)`
    } else if (offer.discountType === 'percentage-off') {
      text = `${amount}% off the 1st ${alternativeLabel}. ${currency}${price} for the remaining ${alternativeLabel}(s)`
    } else {
      const discount = calcuateDiscountTypeAndAmount(offer, price)
      text = `${currency}${discount} for the 1st ${alternativeLabel}. ${currency}${price} for the remaining ${alternativeLabel}(s)`
    }
  } else if (offer.appliedTo === 'entire-program') {
    if (offer.discountType === 'percentage-off') {
      text = `${amount}% OFF ENTIRE PROGRAM`
    } else if (offer.discountType === 'fixed-price') {
      const discount = calcuateDiscountTypeAndAmount(offer, price)
      text = `${currency}${discount} For Each ${alternativeLabel}(s)`
    } else {
      const discount = calcuateDiscountTypeAndAmount(offer, price)
      text = `${currency}${discount} For Each ${alternativeLabel}(s)`
    }
  }

  if (prePayDiscount) {
    text += `${
      text.length ? '. And an additional ' : ''
    }${prePayDiscount}% off for pre-paying`
  }
  return text + '.'
}

export const createRTPText = (applications, currency = '$', prePay) => {
  const price = parseFloat(
    Math.round((applications[0].grossPrice + Number.EPSILON) * 100) / 100
  ).toFixed(2)
  const filteredApplications = applications.filter(
    (application) => !application.tooLateToDo
  )
  let text = ``
  let remaining = false
  let discountsFound = false

  filteredApplications.forEach((application, i) => {
    if (application.discountData && application.discountData.length) {
      application.discountData.forEach((discount, j) => {
        discountsFound = true
        if (i > 0 && text) {
          text += ' '
        }
        if (j > 0) {
          text += ' and '
        }

        const currOrPercent = discount.dollarDiscount >= 2 ? currency : ''

        text += `${currOrPercent}${discount.discountAmount}${
          discount.dollarDiscount === 1 ? '%' : ''
        }`

        if (j + 1 === application.discountData.length) {
          text += ` ${
            discount.dollarDiscount === 3 ? 'for the' : 'off the'
          } ${numberSuffix(i + 1)} application.`
        }
      })
    } else {
      remaining = true
    }
  })

  if (remaining) {
    text += text
      ? ` ${currency}${price} for the remaining applications.`
      : `${currency}${price} for each application.`
  }

  if (!text) {
    text = `${currency}${price} for each application.`
  }

  if (prePay) {
    const prePayDiscountData = filteredApplications.find(
      (application) =>
        application.prePayDiscountData && application.prePayDiscountData.length
    )
    if (prePayDiscountData) {
      text += discountsFound
        ? ` An additional ${prePayDiscountData.prePayDiscountData[0].discountAmount}% off the entire program for pre-paying.`
        : ` ${prePayDiscountData.prePayDiscountData[0].discountAmount}% off the entire program for pre-paying.`
    }
  }

  return text
}

function numberSuffix(number) {
  const singleCheck = number % 10
  const teenCheck = number % 100
  if (singleCheck === 1 && teenCheck !== 11) {
    return number + 'st'
  }
  if (singleCheck === 2 && teenCheck !== 12) {
    return number + 'nd'
  }
  if (singleCheck === 3 && teenCheck !== 13) {
    return number + 'rd'
  }
  return number + 'th'
}

// test
