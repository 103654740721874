import { getRemainingApplications } from './applications'
import { getInstallmentPricing } from './installments'
import { applyOfferToServices } from './offers'
import { resetServicePrices, resetServiceTax } from './transformers'

const _ = require('lodash')

export const getGrossPrice = (services) => {
  const total = _.reduce(
    services,
    (sum, i) => {
      sum += i.grossPrice
      return sum
    },
    0.0
  )
  return Math.round((total + Number.EPSILON) * 100) / 100
}

export const getNetPrice = (services) => {
  const total = _.reduce(
    services,
    (sum, i) => {
      sum += i.netPrice
      return sum
    },
    0.0
  )

  return Math.round((total + Number.EPSILON) * 100) / 100
}

export const getSalesTax = (services) => {
  const total = _.reduce(
    services,
    (sum, i) => {
      if (i.netPrice) {
        sum += i.taxes
      }
      return sum
    },
    0.0
  )
  return Math.round((total + Number.EPSILON) * 100) / 100
}

export const getDisplayPricing = (
  program,
  term = 'application',
  autoPayPriceMethod,
  autoPayMonthlyPricePeriod = 12,
  serviceTax,
  lookupZipCodeTax
) => {
  let services =
    term === 'total' ? program.services.prePay : program.services.application
  if (!program.realTime) {
    services = resetServicePrices(services)
  }

  if (!services.length) throw new Error('No Services')
  services = getRemainingApplications(services)

  const offers = program.offers.filter((t) => t.programId === program.id)
  /// recent request - do offers no matter what

  services = applyOfferToServices(services, offers)

  if (!program.realTime) {
    services = resetServiceTax(
      services,
      serviceTax,
      lookupZipCodeTax,
      true,
      term === 'total' && program.prePayDiscount
        ? parseInt(program.prePayDiscount)
        : null
    )
  }
  let netPrice = getNetPrice(services)
  if (term === 'total') {
    if (program.prePayDiscount) {
      let discount = parseInt(program.prePayDiscount)
      discount = (100 - discount) / 100
      netPrice =
        Math.round((parseFloat(netPrice * discount) + Number.EPSILON) * 100) /
        100
    }
    return netPrice
  } else {
    netPrice = getNetPrice(services)

    let firstPrice = services[0].netPrice
    try {
      firstPrice = getInstallmentPricing(
        netPrice,
        autoPayPriceMethod,
        autoPayMonthlyPricePeriod
      )
    } catch (e) {
      // not monthly
    }
    return Math.round((firstPrice + Number.EPSILON) * 100) / 100
  }
}

export const getSummaryPricing = (
  programs = [],
  term = 'application',
  company
) => {
  const autoPayPriceMethod = company.autoPayPriceMethod
  const autoPayMonthlyPricePeriod = company.autoPayMonthlyPricePeriod || 12
  const serviceTax = company.serviceTax
  const lookupZipCodeTax = company.lookupZipCodeTax
  const currency = company.currency
  const taxOverride =
    company.autoPayPriceMethod === 'monthly-payment'
      ? !!(
          company.taxOverride ||
          company.taxOverride === null ||
          company.taxOverride === undefined
        )
      : false
  const selected = programs
    .filter((i) => i.selected)
    .map((p) => {
      const pricing = getDisplayPricing(
        p,
        term,
        autoPayPriceMethod,
        autoPayMonthlyPricePeriod,
        serviceTax,
        lookupZipCodeTax
      )

      let tax = 0.0

      tax = calculateTaxOnServices(p, company, term !== 'total', term)

      return {
        serviceName: p.serviceName,
        price: pricing,
        tax
      }
    })

  const final = _.reduce(
    selected,
    (sum, s) => {
      sum.price += s.price

      sum.tax += s.tax
      return sum
    },
    { price: 0.0, tax: 0.0 }
  )
  final.price = Math.round((final.price + Number.EPSILON) * 100) / 100
  if (currency !== 'British Pound Sterling') {
    final.tax = taxOverride
      ? '+ TAX'
      : Math.round((final.tax + Number.EPSILON) * 100) / 100
  } else {
    final.tax = taxOverride
      ? ' :(VAT)'
      : Math.round((final.tax + Number.EPSILON) * 100) / 100
  }

  return final
}
// comm

export const calculateTaxOnAmount = (amount, serviceTax = null) => {
  let tax = 0.0
  if (serviceTax) {
    let rate = parseFloat(serviceTax)
    rate = rate / 100
    tax = parseFloat((amount * rate).toFixed(4))
  }
  return tax
}

export const calculateTaxOnServices = (
  program,
  company,
  firstOnly = false,
  term = 'application'
) => {
  const serviceTax = company.serviceTax
  const lookupZipCodeTax = company.lookupZipCodeTax

  let services =
    term === 'total' ? program.services.prePay : program.services.application
  if (!program.realTime) {
    services = resetServicePrices(services)
  }

  if (!services.length) throw new Error('No Services')
  services = getRemainingApplications(services)

  const offers = program.offers.filter((t) => t.programId === program.id)
  services = applyOfferToServices(services, offers)
  if (!program.realTime) {
    services = resetServiceTax(
      services,
      serviceTax,
      lookupZipCodeTax,
      true,
      term === 'total' && program.prePayDiscount
        ? parseInt(program.prePayDiscount)
        : null
    )
  }
  if (firstOnly) {
    services = [services[0]]
  }

  let tax = 0.0
  if (term === 'total' ? true : services[0].netPrice > 0) {
    tax = getSalesTax(services)
  }

  return parseFloat(tax)
}
